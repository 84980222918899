import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { decodeEntities } from "../utils/helpers";
import { AcfComponent } from "./componentLoader";

const ProjectIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {
    wordpressPost: page = [],
    allWordpressWpProjects,
    categoryFilter,
    categories,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { options = null } = siteSettings;
  const { wordpressUrl } = wordpressWpSettings;
  const {
    title,
    yoast,
    acf = {},
  } = page ? page : { title: null, yoast: {}, acf: {} };
  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpProjects;
  const featuredImage =
    wordpressCategory && wordpressCategory.acf.featureImage
      ? wordpressCategory.acf.featureImage
      : "";
  const { layout } = acf || { layout: null };
  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Projects | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
              posts={posts}
            />
          );
        })}
    </>
  );
};

export default ProjectIndexPage;

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "projects" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: layout_page {
          __typename
          ...HeaderQuery
          ...BlogFeedQuery
          ...StaffProfilesQuery
          ...FullWidthImageContainedQuery
          ...FullWidthImageQuery
          ...ImageAndContentBlockQuery
          ...ContentAndIconListQuery
          ...TwoCardBlockQuery
          ...TwoColumnQuery
          ...PostListQuery
        }
      }
    }
    wordpressCategory: wordpressWpProjectCategory(
      slug: { eq: "uncategorised" }
    ) {
      name
      slug
      path
      description
      acf {
        featureImage {
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }
  }
`;
